@import url('../fonts/styles.css');

// colors
$darkGrey:#3B3B3B;
$teal:#65A29A;
$darkTeal:#2F6860;
$black:#000;
$white:#fff;
$mediumGray:#D2D2D2;
$mediumGray2:#EFEFEF;
$lightGray:#FBFBFB;

$fbBlue:#3B5998;
$fbBlueHover:#2f4779;
$liBlue:#0077B5;
$liBlueHover:#005f90;
$tran:transparent;

// layout vars
$body:$darkGrey;
$bodyInverse:$white;
$bodySec:$teal;
$bodySecAcc:$darkTeal;

$list:$darkGrey;
$navLink:$black;
$icon:$teal;

$btnPrimary:$teal;

$header:$black;
$headerSec:$teal;

$heroHeader:$black;
$pageHeader:$white;
$pageSubheader:$teal;

$circBg:$white;
$circBgHover:$teal;

$sideNavLink:$teal;
$sideNavLinkAcc:$darkTeal;

$footerBg:$lightGray;

$formBg:$mediumGray2;

$lineBreak:$mediumGray;

// font vars
$headerFont:'Montserrat';
$subHeaderFont:'Montserrat';
$listFont:'Montserrat';
$btnFont:'Montserrat';
$bodyFont:'Tisa';
@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin pseudoAbsolute {
    content:'';
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
}
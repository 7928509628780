@import 'variables.scss';
@import 'mixins.scss';
/*
Theme Name: Core Theme
Author: Frogspark
Version: 3000
*/


/* * * * * * * * * * * * * * *
 * WordPress Content Classes
 * * * * * * * * * * * * * * */

p {
  line-height: 41px;
  padding-top:0px;
  font-family:'Tisa', sans-serif;
  font-size:20px;
  color:$body;
}

span {
    font-family:$headerFont, sans-serif;
    font-weight: 300 !important;
}

.content-section {
    padding:80px 0px;
	p, ul {
        font-family:'Tisa', sans-serif;
        font-size: 20px;
        line-height: 41px;
    }
    p:not(:last-child), ul:not(:last-child){
        padding-bottom:30px;
    }
    p:last-child {
        margin-bottom:0px;
    }
    ul:not(.sm-icons) {
        list-style-type:none;
        padding-left:20px;
        li::before {
            content:'•';
            position:absolute;
            top:0px;
            left:-20px;
            color:$headerSec;
        }
    }
    ul:not(.service-nav){
        li {
            position:relative;
        }
    }
}

.footer-section {
    padding:80px 0px;
}

img {
  max-width: 100%;
}

a, button {
  text-decoration: none !important;
  outline: none !important;
}

h1, h2, h3, h4, h5 {
  margin: 0px;
  padding: 0px;
  line-height: 1.8em;
  color:$header;
  font-weight: 300 !important;
}

h1 {
    font-family:$headerFont, sans-serif;
    font-size:50px;
    font-weight: 300 !important;
}

h2, h3, h4, h5 {
    font-family:$subHeaderFont, sans-serif;
}

h4  {
    font-size:20px;
    font-family:$bodyFont, sans-serif;
    color:$bodySec!important;
}

a, button {
  -o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  transition:.5s;
  font-family:'Montserrat', sans-serif;
  font-weight: 300 !important;
}

ul {
    li {
        font-family:$list;
        margin: 0px;
        padding: 0px;
        line-height: 1.8em;
    }
}

.hamburger {
    position:absolute;
    right:15px;
    top:37px;
    cursor:pointer;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color:$bodySec;
}

.mobile-navlinks {
    opacity:0;
    position:fixed;
    width:0%;
    height:100%!important;
    z-index:600;
    background-image:url('/img/hero-bg.jpg');
    background-repeat:no-repeat;
    background-size:cover;
    list-style-type:none;
    height:0%;
    padding-left:0px;
    text-align:center;
    transition:.4s ease-in-out;
    .container {
        padding-top:130px;
        width:100%;
        left:-100vw;
        position:absolute;
        li {
            font-size:28px;
            font-family:$headerFont;
            font-weight:300;
            line-height:11.5vh;
            transform: rotate3d(1,0,0,90deg);
            transition:1.25s ease-in-out;
            a {
                color:$body!important;
            }
        }
    }
}

.navbar {
    min-height: 1px;
    height:inherit!important;
    margin-top:25px;
	margin-bottom: 0px;
	padding: 0px;
    ul {
        margin: 0px;
        padding: 0px;
        li {
            list-style:none;
            a {
                padding-top:10px!important;
            }
            a:hover {
                color:$bodySec!important;
            }
        }
    }
    .container {
        .navbar-brand {
            padding: 0px;
            height:auto;
            img {
                height:66px;
                display: block;
                margin-left:15px;
            }
        }
    }
    .container-fluid {
        .navbar-brand {
            margin: 0px;
            img {
                height:66px;
                width:auto;
            }
        }
    }
}

#navbar {
    .navbar-nav {
        margin-top:10px;
        li {
            display:table!important;
            height:37px;
            vertical-align:middle;
            margin:0px 25px;
            > a {
                 font-size: 18px;
                 color:$navLink;
                 margin: 0px;
                 padding: 0px;
                 line-height: 1.8em;
                 font-size:15px;
                 display:table-cell!important;
                 vertical-align:middle;
                 height:100%;
            }
            a:hover:not(.btn) {
                color:$bodySec!important;
            }
            a.btn {
                padding:0px!important;
                margin-top:10px;
            }
        }
        li:last-child {
            margin-top:5px;
            margin-right: 0px !important;
        }
    }
}

.navbar-inverse {
	background-color: transparent;
	border: none;
	position: fixed;
    transition:.5s ease-in-out;
    padding:15px;
}

.navbar-inverse.fixed-navbar {
    margin-top:0px;
    background-color:$footerBg;
    box-shadow:0px 2px 2px rgba(0,0,0,0.05);
}

.topgap {
  height: 0px;
  width: 100%;
  display: block;
}

.btn {
    transition:.25s ease-in-out;
    padding:9px 41px!important;
    min-width:165px;
    border-radius:25px;
    border:1px solid;
    font-size:15px;
    font-family:$btnFont!important;
    font-weight: 300 !important;
}

.btn.btn-primary {
    color:$btnPrimary!important;
    border-color:$btnPrimary;
    background-color:$tran;
}

.btn.btn-primary:hover {
    background-color:$btnPrimary!important;
    color:#fff!important;
}

.mobile-nav-wrap {
}

.mobile-nav {
  display: none;
}

.hero {
    padding:186px 0px 126px 0px;;
    height:initial;
	background-position: bottom center !important;
    background-size: cover !important;
    h1 {
        font-size: 50px;
        margin-bottom: 20px;
        color:$heroHeader;
        line-height:71px;
    }
    p {
      margin-top: 4px;
    }
}

.intro-parallax {
    padding-top:60px!important;
    background-size:cover!important;
    background-repeat:no-repeat!important;
    background-position:center center!important;
    background-attachment:fixed!important;
    h1 {
        color:$pageHeader!important;
    }
    .service-carousel {
        padding-top:50px;
        .owl-controls {
            display:none;
        }
        .item {
                text-align:center;
                a {
                    display:inline-block;
                }
                span.circ {
                    display:block;
                    text-align:center;
                    background-color:$circBgHover;
                    border-radius:50%;
                    position:relative;
                    overflow:hidden;
                    z-index:0;
                    height:100px;
                    width:100px;
                    box-shadow:5px 5px 10px rgba(0,0,0,0.5);
                    img {
                        z-index:5;
                    }
                    img.tick {
                        @include centerer;
                        transition:.25s ease-in-out;
                        width:45px;
                        height:34px;
                    }
                    img.press {
                        @include centerer;
                        opacity:0;
                        transition:.5s ease-in-out;
                        width:30px;
                        height:42px;
                    }
                }
                span.circ::before {
                    content:'';
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    border-radius:50%;
                    background-color:$circBg;
                    z-index:0;
                    transition:.75s ease-in-out;
                }
                span.circ:hover{
                    img.tick {
                        opacity:0;
                    }
                    img.press {
                        opacity:1;
                    }
                }
                span.circ:hover::before {
                    opacity:0;
                    transform:scale(0);
                }
                span.title {
                    color:$bodyInverse;
                    font-size:18px;
                    display:block;
                    clear:both;
                    margin-top:20px;
                }
        }
    }

}

.parallax-no-content {
    height:500px;
    background-attachment:fixed!important;
    background-position:center center!important;
    background-size:cover!important;
}

.intro-about {
    padding:100px 0px;
    p:first-child {
        padding-top:10px;
    }
}

.image-col, .image-col-inner {
    height:270px;
    background-size:cover!important;
    background-repeat:no-repeat!important;
    background-position:center center!important;
    border-radius:10px;
    box-shadow:0px 2px 4px rgba(0,0,0,0.3);
}

.testimonials-section {
    padding-top:0px;
    .row {
        background-image:url('/img/test-bg.png');
        background-repeat:no-repeat;
        background-size:35%;
        background-position:75% 50%;
        position:relative;
        .col-md-12 {
            position:relative;
            h1 {
                border-top:1px solid #D2D2D2;
                padding-top:180px;
            }
            h1.no-bord {
                border:none;
                border-color:transparent;
            }
        }
        .test-wrap {
            text-align:center;
            padding:0px 15% 0px 15%;
            p, span {
                font-size:20px;
            }
            p {
                padding:15px 100px 0px 100px;
                color:$bodySec;
            }
            span.client {
                padding-top:25px;
                display:inline-block;
                font-family:$bodyFont!important;
                color:$bodySecAcc!important;
                line-height: 2em !important;
            }
        }
        .owl-controls {
            width:100%;
            text-align:center;
            display:inline-block;
            
            .owl-nav {
                display:none;
            }
            .owl-dots {
                width:initial;
                text-align:center;
                display:inline-block;
                margin-top:25px;
                .owl-dot {
                    width:30px;
                    height:30px;
                    float:left;
                    margin:10px;
                    position:relative;
                }
                .owl-dot:hover::before {
                    transform:scale(0);
                }
                .owl-dot:hover::after{
                    color:$bodySecAcc;
                }
                .owl-dot.active::before, .owl-dot::after {
                    @include pseudoAbsolute;
                    border-radius:50%;
                    transition:.25s ease-in-out;
                }
                .owl-dot.active::before {
                    border:1px solid $bodySec!important;
                    transition:.25s ease-in-out;
                }
                .owl-dot::after {
                    background-color:$bodySec;
                    transform:scale(0.3);
                }
            }
        }
    }
}

footer {
    background-color:$footerBg;
    h4 {
        padding-bottom:10px;
        font-weight:lighter;
    }
    ul {
        list-style-type:none;
        padding:0px 0px 0px 40px;
        margin:0;
        position:relative;
        .icon {
            top:8px;
            left:-10px;
        }
        li {
            display:block;
            float:left;
            clear:both;
            line-height:28px;
            position:relative;
            padding-bottom:10px;
            .icon {
                top:8px;
                left:-45px;
            }
            a {
                font-family:$headerFont!important;
                color:$body!important;
                transition:.25s ease-in-out;
                font-weight: 300 !important;
            }
            a:hover {
                color:$bodySec!important;
            }
        }
    }
    .address {
    }
    .icon {
        display:block;
        position:absolute;
        top:0;
        left:-45px;
        max-width: 20px;
    }
    .icon.icon-email {
      top: 7px;
    }
    .icon.icon-phone {
      top: 4px;
    }
    .icon.icon-service {
      top: 8px;
      left: 0px;
    }
    .icon.icon-page {
      top: 8px !important;
      left: 0px;
    }
    .sitemap {
        .icon {
            top:-10px;
        }
    }
}

//about subpage

.hero.header {
    h1 {
        color:$pageHeader;
    }
    h2 {
        color:$pageSubheader;
    }
}

.profile-section {
    padding-top:0px;
    .row {
        padding-top:80px;
        position: relative;
    }
    .row:before {
      content: '';
      position: absolute;
      width: 100%;
      left: 0%;
      top: 0px;
      height: 1px;
      display: block;
      background: #d2d2d2;
    }
    .avatar {
        img {
        border-radius:50%;
        }
    }
    .col-sm-8 {
        padding-left:100px;
        h2 {
            font-size:45px!important;
        }
    }

}

.sm-icons {
    list-style-type:none;
    padding-left:0px;
    padding-top:10px;
    li:not(:first-child){
        margin-left:20px;
    }
    li {
        float:left;
        a {
            width:60px;
            height:60px;
            display:table;
            text-align:center;
            border-radius:50%;
            i {
                color:$bodyInverse;
                font-size:35px;
                display:table-cell;
                vertical-align:middle;
            }
        }
        a.fb {
            background-color:$fbBlue;
        }
        a.fb:hover {
            background-color:$fbBlueHover;
        }
        a.li {
            background-color:$liBlue;
        }
        a.li:hover {
            background-color:$liBlueHover;
        }
    }
}

// blog page 

.blog-prev {
    h1 {
        padding-bottom:0px;
    }
    .date {
        color:$bodySec;
        font-size:20px;
        padding-bottom:20px;
    }
}

.blog-prev:not(:first-child){
    .col-md-12 {
        h1 {
            padding-top:40px;
            margin-top:60px;
            border-top:1px solid $lineBreak;
        }
    }    
}

.blog-prev:last-child {
    padding-bottom:40px;
}

// single post 

.single-post {
    .hero.header {
        h1 {
            padding-bottom:0px;
            margin-bottom:0px;
        }
        h4 {
            font-family:$headerFont;
                        font-weight:300;
        }
    }
    .blog-post {
        .img-wrap {
            padding:0px 60px 80px 60px;
        }
    }
}

// services
.content-section.service {
    img.service {
        margin-bottom:40px;
    }
    .image-col {
        box-shadow:none!important;
    }
}

.col-sm-5.nav-wrap {
    .menu-tab {
        display:none;
        background-color:$bodySec;
        position:absolute;
        left:0px;
        width:100%;
        padding:5px 15px;
        i {
            margin-right:15px;
            position: relative;
            top: -3px;
        }
        h4 {
            font-family:$headerFont;
                        font-weight:300;
        }
        h4, i {
            display:inline-block;
            color:$bodyInverse!important;
        }
    }
    .service-nav {
        padding-left:0px;
        list-style-type:none;
        li {
            padding-bottom:35px;
            a {
                color:$sideNavLink;
                font-size:30px;
                line-height: 1.6em;
            }
            a.active {
                color:$sideNavLinkAcc;
            }
            a:hover {
                color:$sideNavLinkAcc;
            }
        }
        li:last-child {
            padding-bottom:0px;
        }
        li::before {
            display:none!important;
        }
    }
}

.content-section.price {
    .service-nav {
        padding-right:25px;
    }
    .price-body {
        padding-left:0px!important;
        padding-right:0px!important;
        text-align:left;
    }
    h1.price {
        padding:25px 0px 20px;
        font-size: 25px !important;
    }
    .nav-wrap {
        padding-top:0px;
    }
    .image-col {
        padding-left:0px!important;
        padding-right:0px!important;
    }
}

.contact-section {
    position:relative;
    #map {
    height:550px;
    padding:0;
    position:relative;
    }

    #map.hidden-controls {
        .gmnoprint {
            display:none!important;
        }
        .gm-style-cc {
            display:none!important;
        }
        a[href^="http://maps.google.com/maps"]{
            display:none !important
        }

        a[href^="https://maps.google.com/maps"]{
            display:none !important
        }
    }

    #map.hidden-controls::after {
        @include pseudoAbsolute;
        background:linear-gradient(45deg, rgba(0,0,0,0), rgba(0,0,0,0.23));
    }

    .form-hide {
        position:absolute;
        bottom:0px;
        left:45%;
        text-align:center;
        display:block;
        background-color:$bodySec;
        color:white;
        padding:10px 0px;
        width:10%;
        border-top-left-radius:10px;
        border-top-right-radius:10px;
        transition:.5s ease-in-out;
        cursor:pointer;
        z-index:500;
    }
    .form-hide:hover {
        background-color:$bodySecAcc;
    }
    .overlay {
        position:absolute;
        height:100%;
        width:100%;
        top:0;
        z-index:4;
        transition:.5s ease-in-out;
        overflow:hidden;
        .container {
            height:100%;
            display:table;
            .row {
                display:table-cell;
                vertical-align:middle;
                .contact-box {
                    margin-top:-3%;
                    padding:0px;
                    background-color:$bodyInverse;
                    border-radius:10px;
                    box-shadow:5px 5px 10px rgba(77,77,77,0.5);
                    overflow:hidden;
                    transition:.45s ease-in-out;
                    h4 {
                        color:$bodyInverse!important;
                        background-color:$bodySec;
                        font-family:$headerFont;
                        font-weight:300;
                        font-weight:300;
                        padding:5px 10px 5px 25px;
                        margin:0;
                    }
                    .ul {
                      margin-left: -5px;
                    }
                    .child-list, .sm-icon {
                      margin-left: 0px !important;
                    }
                    .wrap {
                        padding:0px 30px;
                        display:block;                   
                        .col-xs-4, .col-xs-8 {
                            padding:20px 0px 50px 0px;
                            ul {
                                list-style-type:none;
                                padding-left:0px;
                                padding-top:20px;
                                margin-left: -5px;
                                li {
                                    font-size:15px;
                                    color:$bodySec;
                                    font-family:$headerFont;
                        font-weight:300;
                                    a {
                                        color:$bodySec;
                                    }
                                    a:hover {
                                        color:$bodySecAcc;
                                    }
                                    a:visited {
                                        color:$bodySec;
                                    }
                                    ul {
                                        padding-top:20px;
                                        li:not(:first-child){
                                            margin-left:10px;
                                        }   
                                    }
                                    a {
                                        height:30px;
                                        width:30px;
                                        i {
                                            font-size:15px!important;
                                        }
                                    }
                                }
                            }
                            .gform_title, .gform_description {
                                display:none;
                            }
                            input[type=submit]{
                                @extend .btn;
                                @extend .btn.btn-primary;
                                margin-left:5px;
                            }
                            input[type=submit]:hover{
                                @extend .btn.btn-primary:hover;
                            }
                            input[type=submit]:focus {
                                outline:none!important;
                            }
                            .gform_confirmation_message, .validation_error {
                                color:#f44336;
                                font-family:$headerFont;
                        font-weight:300;
                            }
                            .validation_message {
                                font-size:9px;
                                color: #f44336 !important;
                            }
                            .gform_fields {
                                label {
                                    display:none;
                                }
                                li {
                                    padding:5px;
                                }
                                li:nth-child(1){
                                    float:left;
                                    width:50%;
                                }
                                li:nth-child(2){
                                    float:left;
                                    width:50%;
                                }
                                li:nth-child(3){
                                    float:left;
                                    width:100%;
                                }
                                input, textarea {
                                    background-color:$formBg;
                                    color:$body;
                                    font-family:$headerFont;
                                    font-weight: 300 !important;
                                    border:none;
                                    border-radius:5px;
                                    font-size:14px;
                                    width:100%;
                                    padding:5px 10px;
                                }
                                input:focus, textarea:focus {
                                    outline:none!important;
                                    border-color:$tran!important;
                                    border:none!important;
                                }
                                textarea {
                                    width:100%;
                                    height:110px;
                                    resize:none!important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .overlay.hidden-form {
        .contact-box {
            margin-top:550px!important;
            transition:0.5s ease-in-out;
        }
    }

    .overlay.hidden-form.behind {
        z-index:-1;
    }

}


.serv-item {
  display: block;
  margin: 30px 0px;
}

.serv-item span {
  display: inline-block;
  margin-top: 15px;
}

.serv-bg {
  background-size: cover !important;
  background-position: center center !important;
  width: 100%;
  height: 250px;
  margin: 15px 0px;
}

.single-service .content-section p {
  font-size: 16px !important;
  line-height: 1.6em !important;
}

.page-template-page-services .content-section {
  padding: 25px 0px 80px;
}

// Custom Styles

/**** MEDIA QUERIES ****/

@media screen and (max-width: 1199px) {
    .row.circles .title {
        font-size: 14px!important;
    }
    .service-nav {
        li:not(:last-child) {
            padding-bottom:20px!important;
        }
    }
    .contact-box {
        .col-xs-4 {
            ul {
                li {
                    font-size:12px!important;
                }
            }
        }
    }
    .service-body {
        padding-top:30px;
    }
}

@media screen and (max-width: 1025px){
    .hero {
        h1 {
            font-size:40px!important;
            margin-bottom:0px;
        }
    }
    .testimonials-section {
        .col-md-12 {
            h1 {
                padding-top:120px!important;
            }
        }
    }
} 

@media screen and (max-width: 991px) {
    .navbar-nav {
        li {
            margin:0px 13px!important;
        }
    }
    .hero {
        .col-md-6 {
            h1 {
                margin-bottom:0px;
            }
            p:first-child {
                padding-top:20px;
            }
        }
    }
    .intro-about {
        padding:50px 0px 60px 0px;
        p:first-child {
            padding-top:0px;
        }
        .image-col {
            margin-top:40px;
        }
    }
    .profile-section {
        padding-top:0px;
        .col-sm-8 {
            padding-left:15px;
            h2 {
                font-size:40px;
            }
            p {
                margin-bottom:0px;
            }
        }
    }
    .testimonials-section {
        h1 {
            padding-top:60px!important;
        }
        .test-wrap {
            p {
                padding:15px 0px 0px 0px!important;
            }
        }
    }
    .content-section.service {
        .col-sm-offset-5 {
            clear:both;
        }
    }
    .service-nav {
        li {
            a {
                font-size:20px!important;
            }
        }
    }
    .content-section.price {
        padding:20px 0px 50px 0px!important;
    }

    .contact-box {
        margin-left:20%;
        width:80%;
        .col-xs-4 {
            ul {
                li {
                    font-size:12px!important;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .hero, .content-section, .intro-about {
        padding:40px 0px!important;
    }
    .content-section, .intro-about, .hero {
        p, ul > li {
            font-size:16px!important;
        }
        ul {
            text-align:left;
        }
        p:not(:last-child), ul:not(last-child){
            padding-bottom:20px!important;
            margin-bottom:0px!important;
        }
    }
    .profile-section .row:before {
      content: '';
      position: absolute;
      width: 92%;
      left: 4%;
      top: 0px;
      height: 1px;
      display: block;
      background: #d2d2d2;
    }
    h1 {
        font-size:35px!important;
        line-height:1.6em!important;
    }
    .navbar {
        margin-top:0px;
        padding-top:15px!important;
    }
    .topgap {
        height:96px;
    }
    .navbar-fixed-top {
        background-color:$footerBg;
        padding-bottom:15px;
        box-shadow:0px 2px 4px rgba(0,0,0,0.1);
    }
    .navbar-brand {
        margin-left:0px!important;
        img {
            margin-left:0px!important;
        }
    }
    .mobile-nav {
        display:block;
    }
    .mobile-nav-wrap.show-menu {
        visibility:visible;
        .mobile-navlinks {
            visibility:visible;
            width:100%;
            opacity:1;
            .container {
                left:0;
                li {
                    transform: rotate3d(1,0,0,0deg);
                }
            }
        }
    }
    
    .contact-section {    
      .overlay.hidden-form {
        .contact-box {
            margin-top:700px!important;
            transition:0.5s ease-in-out;
        }
      }
    }

    .intro-parallax {
        background-attachment:scroll!important;
        padding:40px 0px 50px 0px!important;
        .row.circles {
            .col-sm-2 {
                width:inherit!important;
                .circ {
                    width:150px!important;
                    height:150px!important;
                }
                .title {
                    font-size:30px!important;
                }
            }
            .col-sm-2:not(:first-child){
                margin-top:30px;
            }
        }
    }
    .parallax-no-content {
        background-attachment:scroll!important;
        height:350px!important;
    }
    .intro-about {
        padding:40px 0px 0px 0px!important;
        .col-md-6 {
            p {
                padding-top:0px;
            }
            p:last-child {
                padding-bottom:20px;
            }
        }
        .image-col {
            margin:0px 15px;
        }
    }
    .testimonials-section {
        .row {
            background-position:75% 20%;
            h1 {
                padding-top:60px!important;
            }
            h1.no-bord {
                padding-top:0px!important;
            }
            .test-wrap {
                padding:0px 5% 0px 5%;
                p {
                    padding:15px;
                    margin-bottom:0px!important;
                }
            }
            .owl-controls {
                .owl-dots {
                    .owl-dot {
                    }
                    .owl-dot:hover::before {
                        transform:scale(1)!important;
                    }
                }
            }
        }
    }

    .single-post {
        .img-wrap {
            padding:0px 0px 60px 0px!important;
        }
    }

    footer {
        text-align:center;
        .col-sm-4 {
            clear:both;
        }
        .col-sm-4:not(:first-child){
            margin-top:30px;
        }
        .icon {
            display:none;
        }
        ul {
            display:block;
            text-align:center;
            padding-left:0px;
            li {
                float:none;
            }
        }
        .address {
            ul {
                display:inline-block;
                li {
                    text-align:center;
                }
            }
        }
    }

    // about
    .profile-section {
        text-align:center;
        padding-bottom:0px!important;
        padding-top:0px!important;
        .row {
            padding:50px 0px;
        }
        .avatar {
            img {
                width:50%;
                height:auto;
                display:inline;
            }
            padding-bottom:40px;
        }
        .col-sm-8 {
            padding:0px 15px;
            h2 {
                padding-bottom:10px;
            }
            .sm-icons {
                text-align:center;
                padding-top:15px;
                li {
                    float:none!important;
                    display:inline-block;
                }
            }
        }
    }
    .content-section.service {
        padding-top:0px!important;
        .image-col-inner {
            margin-left:15px!important;
            margin-right:15px!important;
        }
        .col-sm-offset-5 {
            text-align:center;
            p:first-child {
                padding-top:30px;
            }
        }
        .image-col {
            box-shadow:none!important;
            width:100%;
            margin-top: 65px !important;
        }
        .col-sm-7 {
            padding-top:0px;
        }
    }
    .content-section.price {
        padding-top:0px!important;
        .col-sm-7.price-body {
            padding-left:15px;
            h1 {
                font-size:25px!important;
                margin-bottom: 15px !important;
            }
        }
        .pull-right {
            float:none!important;
            margin-top:70px;
            width:95.5%;
            margin-left:15px;
            margin-right:15px;
        }
        .image-col {
          max-width: 340px !important;
          margin: auto !important;
          box-shadow: none !important;
          margin-top: 65px !important;
          padding-top: 0px !important;
        }
    }
    .col-sm-5.nav-wrap {
        padding-top:0px!important;
        .menu-tab {
            display:block;
            z-index:8;
        }
        .service-nav {
            position:absolute;
            top:40px;
            padding:25px 0px 25px 0px!important;
            width:100%;
            left:-100%;
            background-color:$bodySecAcc;
            z-index:5;
            transition:0.5s ease;
            li {
                width:100%;
                padding-left:15px;
                padding:0px;
                a {
                    display:block;
                    font-size:20px!important;
                    color:$bodyInverse!important;
                }
            }
        }
        .service-nav.shown {
            background-color:$bodySecAcc;
            left:0%;
            transition:0.2s ease;
            li {
                a {
                    padding-left:15px;
                }
            }
            
            $top: 20;
            $i: 1;

            @for $i from 1 through 6 {

                li:nth-of-type(#{$i}) {
                    top:$top + px;
                }

                $i: $i + 1;
                $top: $top + 50;
            }
        }
    }

    .col-sm-5.nav-wrap.fixed-top {
        position:fixed;
        top:95px;
        width:100%;
        z-index:5;
    }
    .blog-prev:not(:first-child){
        .col-md-12 {
            h1 {
                padding-top:45px;
            }
        }    
    }
    #map {
      height: 700px !important;
    }
    .contact-section .overlay {
        margin-left:initial!important;
        width:100%;
        padding:40px 0px;
        .contact-box {
            width:100%;
            margin-left:initial!important;
        }
    }
    .content-section.singleblog-section {
        padding-top:0px!important;
        .sblog-inner {
            padding-left:0px;
            padding-right:0px;
            .img-wrap {
                padding-bottom:20px!important;
            }
            p {
                padding-left:15px;
                padding-right:15px;
            }
        }
    }
    .form-hide {
    }
}

@media screen and (max-width: 600px) {
    h1 {
        font-size:25px!important;
    }
    .content-section p:not(:last-child){
        padding-bottom:20px!important;
    }
	.testimonials-section {
        padding:0px!important;
        .row {
            h1 {
                padding-top:25px!important;
            }
            h1.no-bord {
                padding-top:40px!important;
            }
            .test-wrap {
                padding:0px;
                p {
                    padding:15px 0px 0px 0px;
                }
            }
        }
        .test-carousel {
            padding-bottom:25px;
        }
    }
    .profile-section .row {
        padding:40px 0px!important;
    }
    .blog-prev:not(:first-child){
        .col-md-12 {
            h2 {
                margin-top:55px!important;
            }
        }
    }
    .blog-prev:last-child {
        padding-bottom:20px;
    }
    .single-post {
        .img-wrap {
            padding:0px 0px 40px 0px!important;
        }
    }
    .service-image.image-col {
        width:100%!important;
        margin:0px!important;
        border-radius:0px!important;
        padding-left:0px;
        padding-right:0px;
        padding-top:40px;
        width:100%;
        height: auto;
        .image-col-inner {
            padding:0px;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            border-radius:0px!important;
        }
    }
    .content-section.service {
        padding-bottom:30px!important;
        .col-sm-offset-5 {
            p:first-child {
                padding-top:30px!important;
            }
        }
    }
    .service-nav {
        padding:10px 0px;
        li {
            line-height:35px!important;
        }
    }
    .content-section.price {
        padding:0px 0px 30px 0px!important;
        .price-body {
            text-align:center;
            margin-top:10px;
            padding-left:15px!important;
            padding-right:15px!important;
        }
    }
}

@media screen and (max-width: 500px) {
    .contact-section {
        .contact-box {
            .col-xs-4, .col-xs-8 {
                clear:both;
                width:100%;
                padding:10px 0px 10px 0px !important;
            }
            .col-xs-8 {
                padding-bottom:30px!important;
            }
        }
    }
    .profile-section {
        .avatar {
            padding-bottom:20px!important;
        }
    }
}

@media screen and (max-width: 450px) {
    .hero h1, h1 {
        font-size:32px!important;
    }
}


